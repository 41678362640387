export default [
  {
    img: "/news/env-embr.jpg",
    title: {
      en: "New!",
      uk: "Новинка!",
    },
    text: {
      en:
        "DL envelopes with traditional Ukrainian embroidery are now available to order.",
      uk:
        "Конверти DL з традиційною українською вишивкою тепер доступні для замовлення.",
    },
    id: "EmbrEnvelopes",
  },
  {
    img: "/news/env-UA.jpg",
    title: {
      en: "Back in stock!",
      uk: "Знову в наявності!",
    },
    text: {
      en:
        "You asked - we did! Your favorite envelopes in patriotic style are available again on our website.",
      uk:
        "Ви просили - ми зробили! Ваші улюблені конверти у патріотичному стилі знову доступні у нас на сайті.",
    },
    id: "UAenvelopes",
  },
  {
    img: "/news/nytorba.jpg",
    title: { en: "Print on paper bags", uk: "Друк на паперових торбах" },
    text: {
      en: "Digital printing on paper bags from 50 pcs.",
      uk:
        "Цифровий друк будь-якої складності та колірності на паперових торбинках від 50 штук.",
    },
    id: "digitalprinttorba",
  },
  // {
  //   img: "/news/nybanderole.jpg",
  //   title: {
  //     en: "Print on parcel packages",
  //     uk: "Друк на бандерольних пакетах",
  //   },
  //   text: {
  //     en: "Digital printing on parcel packages from 50 pcs.",
  //     uk:
  //       "Цифровий друк будь-якої складності та колірності на бандерольних пакетах від 50 штук.",
  //   },
  //   id: "digitalprintbanderole",
  // },
]
